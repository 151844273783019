import React from "react"
import Layout from "../../components/Layout"
import loadable from "@loadable/component"

// Lazy load the TrackheroCaseStudy component
const TrackheroCaseStudy = loadable(
  () => import("../../components/TrackheroCaseStudy"),
  {
    fallback: (
      <div className="loading-fallback">Loading Trackhero Case Study...</div>
    ), // Fallback while loading
  }
)

export default function TrackHero() {
  return (
    <Layout>
      <TrackheroCaseStudy />
    </Layout>
  )
}
